import './App.css';
import Home from './components/pages/HomePage/Home'

function App() {
    document.title = 'เซียนเฮง'

  return (
        <Home />
  );
}

export default App;
